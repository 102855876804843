import { serverTimestamp } from 'firebase/firestore';
import Router from 'next/router';
import { contains, omit, pathOr, splitEvery, values } from 'ramda';
import { ofType } from 'redux-observable';
import * as R from 'remeda';
import { type Observable, from, of } from 'rxjs';
import { auditTime, catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import uuid from 'uuid-random';
import { Countries, Weddings } from '@bridebook/models';
import type { ITask } from '@bridebook/models/source/models/Countries/Tasks.types';
import { PremiumTiers, slugify } from '@bridebook/toolbox/src';
import type { IDatePicker } from '@bridebook/toolbox/src/datepicker/types';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { fetchLocationData } from 'app-shared/lib/search/utils';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import {
  ChecklistActionTypes,
  type IChangeTaskPeriodAction,
  type IDeleteTaskAction,
  type IDeleteTaskGroupAction,
  type ISaveTaskAssigneeAction,
  type IUpdateSubtaskAction,
  type IUpdateSubtasksSuccessAction,
} from 'lib/checklist/action-types';
import { clearSubtask, reInitialiseChecklist } from 'lib/checklist/actions';
import { changedMainTaskPeriodAnalytics } from 'lib/checklist/analytics/actions';
import { getDoneGroup } from 'lib/checklist/selectors';
import { getI18n } from 'lib/i18n/getI18n';
import { getCountryCodeWithFallback } from 'lib/i18n/selectors';
import { fetchSearchPromise } from 'lib/search/actions';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import type { Action, IApplicationState, IEpicDeps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { getLocationName, isSameSexWedding } from 'lib/weddings/selectors';
import { appError } from '../app/actions';
import { getSplitTasks } from './Tasks.utils';
import {
  changeTaskPeriodSuccess,
  closeSubtasksView,
  confirmDeleteMainTaskAnalytics,
  confirmDeleteSubtaskAnalytics,
  fetchRelatedSuppliersSuccess,
  fetchUserChecklistSuccess,
  initialiseChecklistAnalytics,
  updateSubtasksSuccess,
  updateTaskSuccess,
} from './actions';
import { getNextOrder, getNextSubtaskOrder, getTasksExactDate } from './utils';

export const updateGroupEpic = (
  action$: Observable<Action>,
  { state$, cordovaTracker }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ChecklistActionTypes.UPDATE_TASK_START),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { task, checked, updatedMainTasksMethod, taskName },
        },
        state,
      ]) => {
        const {
          weddings: { profile },
          checklist: { tasks },
        } = state;
        const weddingTasks = Weddings._.getById(profile.id).Tasks;

        const { id: groupId } = task;
        const taskIds = values(tasks)
          .filter((t) => t.group === groupId)
          .map((t) => t.id);

        const getPromise = async () => {
          weddingTasks.begin();

          for (const id of taskIds) {
            await weddingTasks.getById(id).set({ done: checked });
          }

          return await weddingTasks.commit();
        };

        return from(getPromise()).pipe(
          tap(() =>
            cordovaTracker.track(TrackingEvent.UpdatedMainTasks, {
              taskId: groupId,
            }),
          ),
          mergeMap(() => [
            updateTaskSuccess({ task, checked }),

            {
              type: 'UPDATE_TASK_ANALYTICS',
              payload: {
                updatedMainTasksMethod,
                checked,
                taskId: groupId,
                taskName,
              },
            },
            clearSubtask(),
          ]),
          catchError((error) => of(appError({ error, feature: 'Checklist' }))),
        );
      },
    ),
  );

export const updateTasksEpic = (action$: Observable<IUpdateSubtaskAction>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(ChecklistActionTypes.UPDATE_SUBTASKS),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { tasks },
        },
        state,
      ]) => {
        const {
          weddings: { profile },
        } = state;
        const weddingTasks = Weddings._.getById(profile.id).Tasks;

        const getPromise = async () => {
          weddingTasks.begin();

          for (const { taskId, checked } of tasks) {
            await weddingTasks.getById(taskId).set({ done: checked });
          }

          return await weddingTasks.commit();
        };

        const method = tasks[0].method;

        return from(getPromise()).pipe(
          mergeMap(() => of(updateSubtasksSuccess({ method }))),
          catchError((error) => of(appError({ error, feature: 'Checklist' }))),
        );
      },
    ),
  );

export const closeTaskDrawerEpic = (
  action$: Observable<IUpdateSubtasksSuccessAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ChecklistActionTypes.UPDATE_SUBTASKS_SUCCESS),
    // Only run epic for non-automatic actions
    filter(({ payload: { method } }) => method === 'active'),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const isCompleted = getDoneGroup(state);

      if (isCompleted) {
        setTimeout(() => {
          Router.push(UrlHelper.checklist.main);
        }, 1000);
      }

      return isCompleted ? of(clearSubtask()) : of();
    }),
  );

export const addTaskEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(ChecklistActionTypes.ADD_TASK),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { groupId = null, period, name },
        },
        state,
      ]) => {
        const {
          weddings: { profile },
          checklist: { tasksInitial },
        } = state;
        const {
          date,
          tasks: { initializedAt },
        } = profile;
        if (!initializedAt) {
          return of();
        }
        const market = gazetteer.getMarketByCountry(state.weddings.profile.l10n.country);
        const weddingId = profile.id;
        const wedding = Weddings._.getById(weddingId);
        const { tasks } = state.checklist;
        const id = uuid();
        const newTask = {
          createdAt: serverTimestamp(),
          custom: true,
          done: false,
          group: groupId || id,
          id,
          name,
          order: groupId
            ? getNextSubtaskOrder(groupId, tasks)
            : getNextOrder(period, tasks, tasksInitial, market, date as IDatePicker, initializedAt),
          period,
        };

        const pTask = wedding.Tasks.getById(id).set(newTask);

        const analyticsAction = {
          type: groupId ? 'ADD_SUBTASK_ANALYTICS' : 'ADD_TASK_ANALYTICS',
          payload: { newTask, name, taskId: id, taskName: name },
        };

        return from(pTask).pipe(
          mergeMap(() =>
            of(
              { type: 'ADD_TASK_SUCCESS' },
              analyticsAction,
              toggleSnackbar('success', getI18n().t('checklist:snackbar.newTaskAdded')),
            ),
          ),
          catchError((error) => of(appError({ error, feature: 'Checklist' }))),
        );
      },
    ),
  );

export const updateTasksTotalsOnWeddingEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ChecklistActionTypes.FETCH_USER_CHECKLIST_SUCCESS),
    withLatestFrom(state$),
    auditTime(1500),
    mergeMap(([{ payload }, state]) => {
      const {
        weddings: { profile },
      } = state;
      if (!payload) return of();
      const { result, source } = payload;
      if (source !== 'server') return of();
      const weddingId = profile.id;
      const wedding = Weddings._.getById(weddingId);
      const currentTotal = pathOr(180, ['tasks', 'total'], profile);
      const currentDone = pathOr(0, ['tasks', 'done'], profile);
      const total = result ? values(result).length : 180;
      const done = result ? values(result).filter((t) => t.done).length : 0;
      if (currentTotal === total && currentDone === done) return of();

      const pTask = wedding.set({ tasks: { done, total } });

      return from(pTask).pipe(
        mergeMap(() => of({ type: 'UPDATED_CHECKLIST_TOTALS', payload: { done, total } })),
        catchError((error) => of(appError({ error, feature: 'Checklist' }))),
      );
    }),
  );

export const assignTaskEpic = (
  action$: Observable<ISaveTaskAssigneeAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ChecklistActionTypes.SAVE_TASK_ASSIGNEE),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { taskId, assignee },
        },
        state,
      ]) => {
        const {
          weddings: { profile },
          checklist: { tasks },
        } = state;
        const weddingTasks = Weddings._.getById(profile.id).Tasks;
        const taskIds = values(tasks)
          .filter((t) => t.group === taskId)
          .map((t) => t.id);

        const getPromise = async () => {
          weddingTasks.begin();

          for (const id of taskIds) {
            await weddingTasks.getById(id).set({ assignee });
          }

          return await weddingTasks.commit();
        };

        return from(getPromise()).pipe(
          mergeMap(() =>
            of({
              type: ChecklistActionTypes.SAVE_TASK_ASSIGNEE_SUCCESS,
            }),
          ),
          catchError((error) => of(appError({ error, feature: 'Checklist' }))),
        );
      },
    ),
  );

export const deleteGroupEpic = (
  action$: Observable<IDeleteTaskGroupAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ChecklistActionTypes.DELETE_TASK_GROUP),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        weddings: { profile },
        checklist: { groups, tasks },
      } = state;
      const { groupId } = action.payload;
      const weddingTasks = Weddings._.getById(profile.id).Tasks;
      const taskIds = values(tasks)
        .filter((t) => t.group === groupId)
        .map((t) => t.id);

      const getPromise = async () => {
        weddingTasks.begin();

        for (const id of taskIds) {
          await weddingTasks.getById(id).delete();
        }

        return await weddingTasks.commit();
      };

      getPromise();

      const task = groups[groupId];

      return of(
        {
          type: 'DELETE_MAIN_TASK_ANALYTICS',
          payload: { task, taskName: pathOr('', ['name'], task) },
        },
        closeSubtasksView(),
      );
    }),
  );

export const deleteTaskEpic = (action$: Observable<IDeleteTaskAction>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(ChecklistActionTypes.DELETE_TASK),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        weddings: { profile },
        checklist: { detailsTaskId },
      } = state;
      const { task } = action.payload;
      const weddingId = profile.id;
      const wedding = Weddings._.getById(weddingId);

      const pDelete = wedding.Tasks.getById(task.id).delete();

      const analyticsAction = detailsTaskId
        ? confirmDeleteSubtaskAnalytics(task)
        : confirmDeleteMainTaskAnalytics(task);

      return from(pDelete).pipe(
        mergeMap(() => of(analyticsAction)),
        catchError((error) => of(appError({ error, feature: 'Checklist' }))),
      );
    }),
  );

export const initialiseChecklistEpic = (
  action$: Observable<Action>,
  { state$, cordovaTracker }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ChecklistActionTypes.INITIALISE_CHECKLIST),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        profile: { date, id, l10n, tasks, roles },
      } = state.weddings;
      if (!date) return of();
      const { initializedAt } = tasks;
      const isSameSexRoles = isSameSexWedding(state);
      const { initial = true } = action.payload;
      const { country } = l10n;
      const wedding = Weddings._.getById(id);
      const weddingTasksRef = wedding.Tasks;
      const market = gazetteer.getMarketByCountry(state.weddings.profile.l10n.country);
      const exactDate = getTasksExactDate(date, market);

      const doBatchUpdate = async (tasks: ITask[]) => {
        weddingTasksRef.begin();

        for (const task of tasks) {
          const obj = omit(['active', 'i18nKey', 'key'], {
            custom: false,
            done: false,
            ...task,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });

          await weddingTasksRef.push(task.id).set(obj);
        }

        return await weddingTasksRef.commit();
      };

      const getPromise = async () => {
        const countries = Countries._.getById(country);
        const getTasks = async () => {
          if (initial) {
            const tasks = await countries.getActiveTasks();
            // pass wedding roles only during first initialisation
            return getSplitTasks(tasks, new Date(exactDate), initializedAt, roles, isSameSexRoles);
          }
          const weddingTasks = await weddingTasksRef.query().get();

          await weddingTasksRef.deleteByIds(Object.keys(weddingTasks));

          return getSplitTasks(weddingTasks, new Date(exactDate), initializedAt);
        };

        const tasks = await getTasks();
        if (!initializedAt) {
          // save initializedAt once
          await wedding.set({
            tasks: { initializedAt: new Date() },
          });
        }
        const batches = splitEvery(250, tasks);

        for (const batch of batches) {
          await doBatchUpdate(batch);
        }
      };

      return from(getPromise()).pipe(
        tap(() => cordovaTracker.track(TrackingEvent.InitializedChecklist)),
        mergeMap((payload) => [
          // @ts-ignore FIXME
          fetchUserChecklistSuccess(payload),
          initialiseChecklistAnalytics(initial),
          reInitialiseChecklist(false),
        ]),
        catchError((error) => of(appError({ error, feature: 'Checklist' }))),
      );
    }),
  );

export const fetchRelatedSupplierEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(ChecklistActionTypes.FETCH_RELATED_SUPPLIERS),
    withLatestFrom(state$),
    mergeMap(([action, state]: [any, IApplicationState]) => {
      const { supplierSlug } = action.payload;
      const area = getLocationName(state) || 'UK';
      const market = gazetteer.getMarketByCountry(state.weddings.profile.l10n.country);

      const minTier =
        supplierSlug === 'venues' && market.country === CountryCodes.GB
          ? PremiumTiers.Tier_1
          : undefined;

      const getPromise = async () => {
        // Fetch additional location data for search context
        const locationData = await fetchLocationData({
          area: slugify(area),
          slug: supplierSlug,
          market,
        });

        const query = {
          slug: supplierSlug,
          area,
          oldSlug: true,
          isLoggedIn: true,
          minTier,
          locationData: {
            ...locationData,
            countryCode: getCountryCodeWithFallback(state),
          },
        };

        return fetchSearchPromise(query);
      };

      return from(getPromise()).pipe(
        map(fetchRelatedSuppliersSuccess),
        catchError((error) => of(appError({ error, feature: 'Checklist' }))),
      );
    }),
  );

export const leaveChecklistEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType('ROUTE_CHANGE_COMPLETE'),
    mergeMap(({ payload: { previousPath, serverPage } }) =>
      contains(UrlHelper.checklist.main, previousPath) &&
      // `serverPage` is the current path, and it can be either the main
      // checklist page or the subtasks page
      !contains(UrlHelper.checklist.main, serverPage)
        ? of(clearSubtask())
        : of(),
    ),
  );

const tasksPeriodChangeEpic = (
  action$: Observable<IChangeTaskPeriodAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ChecklistActionTypes.TASKS_PERIOD_CHANGE),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const period = action.payload;
      const {
        checklist: { detailsTaskId, tasks, groups, tasksInitial },
        weddings: {
          profile: {
            id: weddingId,
            date,
            tasks: { initializedAt },
          },
        },
      } = state;
      if (!initializedAt) {
        return of();
      }
      const market = gazetteer.getMarketByCountry(state.weddings.profile.l10n.country);
      const groupTasksIds = R.pipe<ITask[], ITask[], ITask[], string[]>(
        values(tasks),
        R.filter<ITask>((t) => t.group === detailsTaskId),
        // @ts-ignore FIXME
        R.sortBy(R.prop<ITask, 'order'>('order')),
        R.map<ITask, string>((t) => t.id),
      );
      const latestOrder = getNextOrder(
        period,
        tasks,
        tasksInitial,
        market,
        date as IDatePicker,
        initializedAt,
      );
      if (!latestOrder) {
        throw new Error('latestOrder is undefined');
      }

      const weddingTasks = Weddings._.getById(weddingId).Tasks;

      const getPromise = async () => {
        let orderingIndex = 0;

        weddingTasks.begin();

        for (const id of groupTasksIds) {
          const order = latestOrder + (orderingIndex++ + 1) * 0.01;

          await weddingTasks.getById(id).set({
            period,
            order,
          });
        }

        return await weddingTasks.commit();
      };

      // Analytics
      const mainTask = groups[detailsTaskId as string];

      return from(getPromise()).pipe(
        mergeMap(() => of(changeTaskPeriodSuccess(), changedMainTaskPeriodAnalytics(mainTask))),
        catchError((error) => of(appError({ error, feature: 'Checklist' }))),
      );
    }),
  );

export const pageEpics = [
  fetchRelatedSupplierEpic,
  leaveChecklistEpic,
  tasksPeriodChangeEpic,
  assignTaskEpic,
  deleteGroupEpic,
  deleteTaskEpic,
  addTaskEpic,
];
