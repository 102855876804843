import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap, withLatestFrom } from 'rxjs/operators';
import { isArchivedByCouple } from '@bridebook/toolbox/src/inbox/utils';
import { App } from '@capacitor/app';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { AuthActionTypes } from 'lib/auth/action-types';
import { getCoupleChatUserOptions } from 'lib/inbox/selectors';
import { TalkJsManager } from 'lib/inbox/talkjs';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';
import { IUpdateWeddingAction, WeddingActionTypes } from 'lib/weddings/action-types';
import { updateInboxUnread } from 'lib/weddings/actions';

const watchInboxUnread$ = (state: IApplicationState) => {
  const chatUserOptions = getCoupleChatUserOptions(state);
  if (!chatUserOptions) return of();

  return new Observable((observer) => {
    (async () => {
      const talkManager = await TalkJsManager();
      const talkSession = await talkManager.get(chatUserOptions);

      // Set number of unread messages
      talkSession.unreads.onChange((unreadConversations) => {
        observer.next(updateInboxUnread(unreadConversations));
      });

      // Trigger haptic feedback on new message
      talkSession.onMessage((message) => {
        App.getState().then(({ isActive }) => {
          // Only when app currently in foreground and message is not sent by user
          // We add a trivial exception handler because this method always throws in Safari:
          if (isActive && !message.isByMe && !isArchivedByCouple(message.conversation)) {
            Haptics.notification({
              type: NotificationType.Success,
            }).catch((_) => _);
          }
        });
      });
    })();
  });
};

export const initInboxListenerEpic = (
  action$: Observable<IUpdateWeddingAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType<Action>(WeddingActionTypes.UPDATE_WEDDING, AuthActionTypes.USER_SETUP_COMPLETED),
    withLatestFrom(state$),
    distinctUntilChanged(
      ([prevAction], [nextAction]) =>
        prevAction.payload.wedding.id === nextAction.payload.wedding.id,
    ),
    switchMap(([, state]) => watchInboxUnread$(state)),
  );
